.brandsSec {
  display: flex;
  column-gap: 60px;
  padding: 20px;
  margin: 30px 0;
}
.mainRap .icon {
  margin-bottom: 10px;
  font-size: 35px;
  color: #027cc5;
}

.mainRap .text h6 {
  text-transform: uppercase;
  font-size: 18px;
}

.mainRap .text p {
  font-size: 14px;
  line-height: 22px;
}

.mainRap {
  padding: 0 10px;
}
.brandsItem ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
}

.brandsItem ul li a {
  display: flex;
  background: #ffffff;
  padding: 35px;
  text-align: center;
  box-shadow: 0 0 8px 0 rgb(7 7 7 / 10%);
  height: 137px;
  justify-content: center;
  width: 100%;
}
