.logo a {
  width: 262px;
}


.searchIcons {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.searchIcon {
  width: 50px;
  background: #303030;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchSec {
  background-color: #027cc5;
  padding: 5px 0;
  position: relative;
  display: flex;
  column-gap: 20px;
}

.sticky.active.searchSec {
  padding: 10px 0 5px;
}

.searchItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Bar Start */
.searchItem .logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bar svg {
  font-size: 29px;
  color: #fff;
  cursor: pointer;
  display: none;
}

/* search Info */
.searchInf {
  background-color: #f1f1f3;
  width: 570px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  border-radius: 0;
}

.searchInf input {
  background-color: #f1f1f3;
  padding: 0 15px;
  height: 44px;
  border-radius: 0;
  font-size: 16px;
  border: 0;
  color: #333333;
}

.searchInf input:focus {
  box-shadow: none;
  border: 0;
  background-color: #efefef;
}

.rightSearchInfo {
  display: flex;
  border-left: 1px solid #d9d9d9;
}

.allCategory {
  border-right: 1px solid #efefef;
}

.allCategory select {
  background-color: transparent;
  border: 0;
  padding: 12px 8px;
  font-size: 16px;
  color: #333333;
}

.allCategory select:focus {
  outline: 0;
}

.searchIcon svg {
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
}

/* Call Details */
.callDetail {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.callDetail svg {
  font-size: 20px;
}

.callText h6 {
  margin: 0;
  text-transform: uppercase;
  color: gray;
  font-size: 12px;
}

.callText p {
  color: #111;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}

.searchIconsItem ul {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin: 0;
  padding: 0;
}

.searchIconsItem ul li a {
  font-size: 25px;
  color: #fff;
  line-height: 20px;
}

.countParent a {
  position: relative;
}

.count {
  display: flex;
  background-color: #222;
  color: #fff;
  font-size: 13px;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 600;
  position: absolute;
  top: -10px;
  right: -10px;
}

/* Trending */
.treandingSec {
  box-shadow: 0 0 8px 0 rgb(7 7 7 / 10%);
  background: #fff;
  position: absolute;
  width: 100%;
  top: 60px;
  transition: 0.4s ease-in-out;
  z-index: 1;
}

.trendingItem {
  border: 1px solid #e8e7e67d;
  border-top: none;
  padding: 16px 15px;
}

.trendingTitle {
  font-size: 13px;
  line-height: 1.38;
  color: #333333;
}

.trendingItem ul {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.trendingItem ul li a {
  padding: 9px 8px;
  border: 1px solid #e8e7e6;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  color: #333333;
  transition: 0.4s ease-in-out;
  text-transform: uppercase;
  font-weight: 500;
}

.trendingItem ul li a:hover {
  color: #fff;
  border: solid 1px #027cc5;
  background-color: #027cc5;
}

.closeBtnDrop {
  font-size: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #027cc5;
  cursor: pointer;
}

.trendingItem {
  position: relative;
}

.userNamePro .helll > li svg {
  color: #027cc5;
}



.searchItem .logo.mamas a .text strong {
  text-transform: uppercase;
  color: #333;
  white-space: nowrap;
}

.searchItem .logo.mamas a .text p {
  text-transform: uppercase;
  color: #333;
  font-size: 13px;
  line-height: 13px;
}
.brandText p {
  font-size: 14px;
  line-height: 16px;
}
